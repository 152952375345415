import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import Loader from 'components/loader/loader';
import TopBIMethodologies from 'pages/resources/ebooks/top-BI-methodologies';

const Home = lazy(() => import('pages/Home/index'));
const JoinUsPage = lazy(() => import('pages/join-us/index'));
const ConnectPage = lazy(() => import('pages/connect/index'));
const DigitalPage = lazy(() => import('pages/digital/index'));
const CXPage = lazy(() => import('pages/cx/index'));
const VelocityPage = lazy(() => import('pages/velocity/index'));
const FinancialPage = lazy(() => import('pages/financial/index'));
const HealthPage = lazy(() => import('pages/health/index'));
const LeadershipPage = lazy(() => import('pages/leadership'));
const NotFoundPage = lazy(() => import('pages/not-found'));
const Adcast = lazy(() => import('pages/adcast'));
const BundleDealer = lazy(() => import('pages/bundledealer/index'));
const BusinessIntelligence = lazy(() =>
    import('pages/business-intelligence/index')
);
const PrivaryPolicy = lazy(() => import('pages/privacy-policy'));
const AtHome = lazy(() => import('pages/at-home/index'));
const DigitalConferance = lazy(() => import('pages/digital-conference'));
const EventIndex = lazy(() => import('pages/event'));
const EventTemplate = lazy(() => import('pages/events/template'));

const GBPIndex = lazy(() => import('pages/governance-in-action/index'));
const FSAwards = lazy(() => import('pages/frost'));
const Utilities = lazy(() => import('pages/utility-starter-kit'));
const PixelPerfectSubscription = lazy(() =>
    import('pages/pixel-perfect-subscriptions')
);
const SubscriptionStreetWearInfluencer = lazy(() =>
    import('pages/subscription-streetwear-influencer')
);
const RunwayToTheHolidays = lazy(() => import('pages/runway-to-the-holidays'));
const CaseStudies = lazy(() =>
    import('pages/resources/case-studies/case-studies')
);
const SocialRepMan = lazy(() =>
    import('pages/digital/social-reputation-management/index')
);
const Pharma = lazy(() =>
    import('pages/resources/case-studies/healthcare-pharmacare-CX')
);
const HealthWP = lazy(() =>
    import('pages/resources/whitepapers/healthcare-digital-patient-experience')
);
const FinancialServices = lazy(() =>
    import('pages/industries/financial-services-cx')
);
const WebinarIndex = lazy(() => import('pages/resources/webinars'));
const Aep = lazy(() => import('pages/health/burst-capacity-aep'));
const WaveZero = lazy(() => import('pages/resources/blogs'));
const WaveZeroBlog = lazy(() => import('pages/resources/blogs/blog-detail'));
const WURedirect = lazy(() =>
    import('pages/resources/case-studies/western-union-case-study')
);
const EbooksIndex = lazy(() => import('pages/resources/ebooks'));
const TrainingSimulator = lazy(() =>
    import('pages/technology/training-simulator')
);
const RetailEcommerce = lazy(() => import('pages/industries/retail-ecommerce'));
const Witness = lazy(() => import('pages/technology/witness'));
const Round = lazy(() => import('pages/technology/round'));
const VitualSystemManage = lazy(() =>
    import('pages/technology/virtual-floor-management-system')
);
const Flecx = lazy(() => import('pages/technology/flecx/index'));
const Scorecard = lazy(() => import('pages/technology/scorecard'));
const Inspire = lazy(() => import('pages/technology/inspire'));
const Reflecx = lazy(() => import('pages/technology/reflecx/index'));
const HeatMap = lazy(() => import('pages/technology/heatmap'));

const WhyOutSource = lazy(() => import('pages/why-outsource'));
const ResourcesLandingPage = lazy(() => import('pages/resources/landing-page'));
const Resources = lazy(() => import('pages/resources'));
const ContactUs = lazy(() => import('pages/contact-us'));
const ThankYou = lazy(() => import('pages/thank-you'));
const CSWeek = lazy(() => import('pages/utilities/csweek2021'));
const IpadGiveAway = lazy(() =>
    import('pages/utilities/csweek2021/ipadgiveaway')
);
const DeliveryLogistics = lazy(() =>
    import('pages/industries/delivery-logistics')
);
const HealthTechWellness = lazy(() =>
    import('pages/industries/healthtech-wellness')
);
const StreamingEntert = lazy(() =>
    import('pages/industries/streaming-entertainment')
);
const Technology = lazy(() => import('pages/industries/technology'));
const Travelhospital = lazy(() =>
    import('pages/industries/travel-hospitality')
);

const DigitalTransformRec = lazy(() =>
    import(
        'pages/resources/webinars/digital-transformation-through-disruption-recording'
    )
);
const FintechCXAward = lazy(() => import('pages/fintech-cx-awards'));
// const FintechAwards2024 = lazy(() => import('pages/cx-leadership-awards/2024'));
const CxLeaderShipAwards2025 = lazy(() => import('pages/cx-leadership-awards'));

const AllCaseStudies = lazy(() =>
    import('pages/resources/case-studies/all-case-studies')
);
const AllWebinars = lazy(() => import('pages/resources/webinars/all-webinars'));
const AllEbooks = lazy(() => import('pages/resources/ebooks/all-ebooks'));
const CXGlossary = lazy(() => import('pages/cx-glossary/index'));
const CXGlossaryDetail = lazy(() => import('pages/cx-glossary/detail'));
const Money2020 = lazy(() => import('pages/events/money2020'));
const StaffAug = lazy(() => import('pages/staff-augmentation'));
const Diversity = lazy(() => import('pages/diversity-equity-inclusion'));
const CoreValues = lazy(() => import('pages/core-values'));
const JobListing = lazy(() => import('pages/join-us/careers/job-listing'));
const LearnAboutIbex = lazy(() => import('pages/learn-about-ibex'));
const LearnAboutIbexHealth = lazy(() =>
    import('pages/learn-about-ibex-health')
);
const UtilitiesRework = lazy(() => import('pages/utilities'));
const ESGPage = lazy(() => import('pages/esg'));

const CBRedirector1 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-1'
    )
);

const CBRedirector2 = lazy(() =>
    import(
        'pages/resources/ebooks/community-bank-digital-member-experience-part-2'
    )
);

const WaveX = lazy(() => import('pages/technology/wavex'));
const CXPerformanceWaveX = lazy(() =>
    import('pages/technology/wavex/cx-performance-acceleration')
);
const CXManagementWaveX = lazy(() =>
    import('pages/technology/wavex/cx-interaction-management')
);
const OmniChannelSuiteWaveX = lazy(() =>
    import('pages/technology/wavex/omnichannel-cx-engagement')
);

const AIPage = lazy(() => import('pages/ai'));

// const Sites = lazy(() => import('pages/sites'));
// const SiteDetail = lazy(() => import('pages/sites/site-detail'));
const PKSiteSection = lazy(() =>
    import('pages/join-us/careers/pk-site-sections')
);

const WaveIx = lazy(() => import('pages/wave-ix'));
const Translate = lazy(() => import('pages/wave-ix/translate'));
const Automate = lazy(() => import('pages/wave-ix/automate'));
const Authenticate = lazy(() => import('pages/wave-ix/authenticate'));
const VirtualAgent = lazy(() => import('pages/wave-ix/virtual-agent'));

const DoNotCall = lazy(() => import('pages/do-not-call'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Router basename="/">
                <Switch>
                    <Route exact path="/resources/ebooks/BI-ebook">
                        <TopBIMethodologies />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/join-us">
                        <JoinUsPage />
                    </Route>
                    <Route exact path="/join-us/pk-sites">
                        <PKSiteSection />
                    </Route>
                    <Route exact path="/join-us/:country">
                        <JobListing />
                    </Route>
                    <Route path="/leadership">
                        <LeadershipPage />
                    </Route>
                    <Route path="/adcast">
                        <Adcast />
                    </Route>
                    <Route path="/bundledealer">
                        <BundleDealer />
                    </Route>
                    <Route path="/business-intelligence">
                        <BusinessIntelligence />
                    </Route>
                    <Route path="/at-home">
                        <AtHome />
                    </Route>
                    <Route path="/digital-conference">
                        <DigitalConferance />
                    </Route>
                    <Route exact path="/event">
                        <EventIndex />
                    </Route>
                    <Route path="/governance-in-action">
                        <GBPIndex />
                    </Route>
                    <Route path="/utility-starter-kit">
                        <Utilities />
                    </Route>
                    <Redirect
                        from={'/resources/blogs/inquiries/exsascko-molopoxj'}
                        to={'/resources/blogs'}
                    />
                    <Redirect
                        from={
                            '/resources/blogs/6-ways-to-improve-customer-retention-with-ai/'
                        }
                        to={
                            '/resources/blogs/6-ways-to-improve-customer-retention-with-ai'
                        }
                    />
                    <Redirect
                        from={
                            '/resources/ebooks/g/community-bank-digital-member-experience'
                        }
                        to={
                            '/resources/ebooks/g/community-bank-digital-customer-experience'
                        }
                    />
                    <Route path="/resources/:type/g/:slug">
                        <ResourcesLandingPage />
                    </Route>
                    <Route
                        exact
                        path="/resources/case-studies/healthcare-pharmacare-CX">
                        <Pharma />
                    </Route>
                    <Route exact path="/connect">
                        <ConnectPage />
                    </Route>
                    <Route exact path="/digital/social-reputation-management">
                        <SocialRepMan />
                    </Route>
                    <Route exact path="/digital">
                        <DigitalPage />
                    </Route>
                    <Route path="/cx">
                        <CXPage />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivaryPolicy />
                    </Route>
                    <Route path="/velocity">
                        <VelocityPage />
                    </Route>
                    <Route path="/financial">
                        <FinancialPage />
                    </Route>
                    <Route exact path="/health">
                        <HealthPage />
                    </Route>
                    <Route path="/frost">
                        <FSAwards />
                    </Route>
                    <Route exact path="/resources/blogs">
                        <WaveZero />
                    </Route>
                    <Route path={`/resources/blogs/:topicId`}>
                        <WaveZeroBlog />
                    </Route>
                    <Route path="/pixel-perfect-subscriptions">
                        <PixelPerfectSubscription />
                    </Route>
                    <Route path="/subscription-streetwear-influencer">
                        <SubscriptionStreetWearInfluencer />
                    </Route>
                    <Route path="/runway-to-the-holidays">
                        <RunwayToTheHolidays />
                    </Route>
                    <Route
                        exact
                        path="/resources/case-studies/western-union-case-study">
                        <WURedirect />
                    </Route>
                    <Route exact path="/resources/case-studies/:slug">
                        <CaseStudies />
                    </Route>
                    <Route path="/health/burst-capacity-aep">
                        <Aep />
                    </Route>
                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-1">
                        <CBRedirector1 />
                    </Route>
                    <Route path="/resources/ebooks/community-bank-digital-member-experience-part-2">
                        <CBRedirector2 />
                    </Route>
                    <Route path="/resources/ebooks/:slug">
                        <EbooksIndex />
                    </Route>
                    <Route
                        exact
                        path="/resources/whitepapers/healthcare-digital-patient-experience">
                        <HealthWP />
                    </Route>
                    <Route
                        exact
                        path="/resources/webinars/digital-transformation-through-disruption-recording">
                        <DigitalTransformRec />
                    </Route>
                    <Route path="/resources/webinars/:slug">
                        <WebinarIndex />
                    </Route>
                    <Route path="/first-time-cx-outsourcing-guide">
                        <WhyOutSource />
                    </Route>
                    <Route path="/contact-us">
                        <ContactUs />
                    </Route>
                    <Route path="/thank-you">
                        <ThankYou />
                    </Route>
                    <Route exact path="/utilities/csweek2021">
                        <CSWeek />
                    </Route>
                    <Route exact path="/utilities/csweek2021/ipadgiveaway">
                        <IpadGiveAway />
                    </Route>
                    <Route path="/technology/scorecard">
                        <Scorecard />
                    </Route>
                    <Route path="/technology/inspire">
                        <Inspire />
                    </Route>
                    <Route path="/technology/flecx">
                        <Flecx />
                    </Route>
                    <Route path="/technology/heatmap">
                        <HeatMap />
                    </Route>
                    <Route path="/technology/reflecx">
                        <Reflecx />
                    </Route>
                    <Route path="/technology/witness">
                        <Witness />
                    </Route>
                    <Route path="/technology/round">
                        <Round />
                    </Route>
                    <Route path="/technology/virtual-floor-management-system">
                        <VitualSystemManage />
                    </Route>
                    <Route path="/technology/training-simulator">
                        <TrainingSimulator />
                    </Route>
                    <Route exact path="/technology/wavex">
                        <WaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/cx-performance-acceleration">
                        <CXPerformanceWaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/cx-interaction-management">
                        <CXManagementWaveX />
                    </Route>
                    <Route
                        exact
                        path="/technology/wavex/omnichannel-cx-engagement">
                        <OmniChannelSuiteWaveX />
                    </Route>
                    <Route path="/industries/financial-services-cx">
                        <FinancialServices />
                    </Route>
                    <Route path="/industries/retail-ecommerce">
                        <RetailEcommerce />
                    </Route>
                    <Route path="/industries/delivery-logistics">
                        <DeliveryLogistics />
                    </Route>
                    <Route path="/industries/healthtech-wellness">
                        <HealthTechWellness />
                    </Route>
                    <Route path="/industries/streaming-entertainment">
                        <StreamingEntert />
                    </Route>
                    <Route path="/industries/technology">
                        <Technology />
                    </Route>
                    <Route path="/industries/travel-hospitality">
                        <Travelhospital />
                    </Route>
                    <Route exact path="/events/money2020">
                        <Money2020 />
                    </Route>
                    <Route exact path="/events/:slug">
                        <EventTemplate />
                    </Route>
                    <Route path="/diversity-equity-inclusion">
                        <Diversity />
                    </Route>
                    <Route exact path="/resources">
                        <Resources />
                    </Route>
                    <Route exact path="/resources/case-studies">
                        <AllCaseStudies />
                    </Route>
                    <Route exact path="/resources/webinars">
                        <AllWebinars />
                    </Route>
                    <Route exact path="/resources/ebooks">
                        <AllEbooks />
                    </Route>
                    <Route exact path="/fintech-cx-awards">
                        <FintechCXAward />
                    </Route>
                    {/* <Route path="/cx-leadership-awards/2024">
                        <FintechAwards2024 />
                    </Route> */}
                    <Route path="/cx-leadership-awards">
                        <CxLeaderShipAwards2025 />
                    </Route>
                    <Route exact path="/cx-glossary/:slug">
                        <CXGlossaryDetail />
                    </Route>
                    <Route exact path="/cx-glossary">
                        <CXGlossary />
                    </Route>
                    <Route exact path="/staff-augmentation">
                        <StaffAug />
                    </Route>
                    <Route exact path="/core-values">
                        <CoreValues />
                    </Route>
                    <Route path="/learn-about-ibex">
                        <LearnAboutIbex />
                    </Route>
                    <Route path="/learn-about-ibex-health">
                        <LearnAboutIbexHealth />
                    </Route>
                    <Route path="/utilities">
                        <UtilitiesRework />
                    </Route>
                    <Route path="/esg">
                        <ESGPage />
                    </Route>
                    {/* <Route path="/ai">
                        <AIPage />
                    </Route> */}
                    <Redirect from={'/ai'} to={'/wave-ix'} />
                    <Route exact path="/wave-ix">
                        <WaveIx />
                    </Route>
                    <Route exact path="/wave-ix/translate">
                        <Translate />
                    </Route>
                    <Route exact path="/wave-ix/automate">
                        <Automate />
                    </Route>
                    <Route exact path="/wave-ix/authenticate">
                        <Authenticate />
                    </Route>
                    <Route exact path="/wave-ix/ai-virtual-agent">
                        <VirtualAgent />
                    </Route>
                    <Route exact path="/donot-call">
                        <DoNotCall />
                    </Route>
                    {/* <Route exact path="/sites">
                        <Sites />
                    </Route>
                    <Route path="/sites/:slug">
                        <SiteDetail />
                    </Route> */}
                    {/* Redirection */}
                    <Route
                        exact
                        path="/cellbenefit"
                        component={() => {
                            window.location.replace(
                                'https://app.smartsheet.com/b/form/0223de7cd1e145d0ac25bc59c0920dfe'
                            );
                            return null;
                        }}></Route>
                    <Redirect from={'/casestudy'} to={'/resources'} />

                    <Redirect
                        from={'/cx-leadership-awards/2023'}
                        to={'/cx-leadership-awards/2024'}
                    />
                    <Redirect from={'/20230630'} to={'/'} />
                    <Redirect from={'/role/GoodwillDetails'} to={'/'} />

                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    );
};

export default MainRoutes;
