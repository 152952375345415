export const gilroyRegular = '"poppinsregular", sans-serif';
export const gilroySemibold = '"poppinssemibold", sans-serif';
export const gilroyBold = '"poppinsbold", sans-serif';
export const gilroyExtraboldItalic = '"poppinsextrabold_italic", sans-serif';
export const gilroyBlack = '"poppinsblack", sans-serif';
export const gilroyExtrabold = '"poppinsextrabold", sans-serif';
export const gilroyHeavy = '"poppinsextrabold", sans-serif';
export const gilroyHeavyItalic = '"poppinsextrabold_italic", sans-serif';
export const gilroyLight = '"poppinslight", sans-serif';
export const gilroyMedium = '"poppinsmedium", sans-serif';
export const gilroyMediumItalic = '"poppinsmedium_italic", sans-serif';
export const sourceSansPro = '"Source Sans Pro", sans-serif';
export const akiraSuperBold = '"poppinsextrabold", sans-serif';
export const morisonRegular = 'poppinsregular';
export const morisonBold = 'poppinsbold';
export const morisonSemiBold = 'poppinssemibold';
export const morisonMedium = 'poppinsmedium';
export const morisonLight = 'poppinslight';
export const morisonLightItalic = 'poppinslight_italic';
export const axiformaBold = 'poppinsbold';
export const axiformaSemiBold = 'poppinssemibold';
export const axiformaMedium = 'poppinsmedium';
export const montserrat = '"Montserrat",serif';
export const anton = '"Anton", serif';
